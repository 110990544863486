import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Close from "../Icons/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "./StepsToIntegrateModal.css";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MERCHANT_ID } from "constants/Constants";
import { processors } from "utils/processors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 616,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  maxHeight: "80vh",
  overflowY: "auto",
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: "32px",
  padding: "8px 12px 8px 12px",
  gap: "6px",
  borderRadius: "6px 0px 0px 0px",
  opacity: "0px",
  backgroundColor: "var(--bg-weak-50, #f5f7fa)",
  width: "584px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "0.04em",
  textAlign: "left",
  color: "var(--text-sub-600, #525866)",
}));

const StepsToIntegrateModal = ({ open, setOpen, processorCode }) => {
  const [expanded, setExpanded] = useState(false);
  const processor = processors.find(
    (p) => p.processorCode.toLowerCase() === processorCode.toLowerCase()
  );

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} sx={{ width: "auto" }}>
      <Box sx={style} className="steps-to-integrate-modal">
        <div className="modal-header">
          <span className="modal-header-container">
            <HelpOutlineOutlinedIcon />
            <div className="modal-header-title-container">
              <div className="modal-header-title">Steps to Integrate</div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <Divider />
        <Box sx={{ margin: "16px" }}>
          {processor.info.map((info, index) => (
            <Accordion
              key={index}
              sx={{ marginBottom: "16px" }}
              onChange={handleChange(index)}
              expanded={expanded === index}
            >
              <StyledAccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                expandIcon={
                  expanded === index ? (
                    <RemoveCircleOutlineOutlinedIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )
                }
              >
                <StyledTypography> {info.title.toUpperCase()}</StyledTypography>
              </StyledAccordionSummary>
              <AccordionDetails>
                {info.content.map((content, idx) => {
                  return (
                    <Typography
                      className="dropdown-description-text"
                      key={idx}
                      dangerouslySetInnerHTML={{
                        __html: content.replace("MERCHANT_ID", MERCHANT_ID),
                      }}
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default StepsToIntegrateModal;
