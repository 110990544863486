import React, { createContext, useContext, useState } from "react";
const MenuFunctionsContext = createContext();

export const MenuFunctionsProvider = ({ children }) => {
  const [toggleExpand, setToggleExpand] = useState(false);

  const menuToggleExpand = () => {
    setToggleExpand(!toggleExpand);
    console.log("testing expand")
  };

  return (
    <MenuFunctionsContext.Provider
      value={{
        toggleExpand,
        menuToggleExpand,
      }}
    >
      {children}
    </MenuFunctionsContext.Provider>
  );
};

export const useMenuFunctions = () => useContext(MenuFunctionsContext);
