import dayjs from "dayjs";

const convertFromDate = (dateString) => {
  const date = dayjs(dateString);
  const formattedDate = date.format("MMM DD YYYY");

  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const dateParts = formattedDate.split(" ");
  const monthName = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  const month = monthMap[monthName];
  let fromDate = `${year}-${month}-${day}`;

  return fromDate;
};

export default convertFromDate;

export const convertFromTime = (getTime) => {
  const hour = getTime?.$H;
  const minutes = getTime?.$m;
  const sec = getTime?.$s;
  const hoursDigit = hour?.toString().length === 1;
  const minutesDigits = minutes?.toString().length === 1;
  const secDigits = sec?.toString().length === 1;

  let fromTime = `${hoursDigit ? 0 : ""}${hour}:${
    minutesDigits ? 0 : ""
  }${minutes}:${secDigits ? 0 : ""}${sec}.000+0000`;

  return fromTime;
};

export const convertToDate = (dateString) => {
  const date = dayjs(dateString);
  const formattedDate = date.format("MMM DD YYYY");

  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const dateParts = formattedDate.split(" ");
  const monthName = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  const month = monthMap[monthName];
  let toDate = `${year}-${month}-${day}`;

  return toDate;
};

export const convertToTime = (getTime) => {
  const hour = getTime?.$H;
  const minutes = getTime?.$m;
  const sec = getTime?.$s;
  const hoursDigit = hour?.toString().length === 1;
  const minutesDigits = minutes?.toString().length === 1;
  const secDigits = sec?.toString().length === 1;
  let toTime = `${hoursDigit ? 0 : ""}${hour}:${
    minutesDigits ? 0 : ""
  }${minutes}:${secDigits ? 0 : ""}${sec}.000+0000`;

  return toTime;
};
