import React from "react";

const FloppyDisk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.1336 6.25001L13.75 2.86641C13.6343 2.74983 13.4967 2.65741 13.345 2.59451C13.1933 2.5316 13.0306 2.49948 12.8664 2.50001H3.75C3.41848 2.50001 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41849 2.5 3.75001V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V7.1336C17.5005 6.96939 17.4684 6.80671 17.4055 6.65502C17.3426 6.50333 17.2502 6.36566 17.1336 6.25001ZM13.125 16.25H6.875V11.875H13.125V16.25ZM16.25 16.25H14.375V11.875C14.375 11.5435 14.2433 11.2255 14.0089 10.9911C13.7745 10.7567 13.4565 10.625 13.125 10.625H6.875C6.54348 10.625 6.22554 10.7567 5.99112 10.9911C5.7567 11.2255 5.625 11.5435 5.625 11.875V16.25H3.75V3.75001H12.8664L16.25 7.1336V16.25ZM12.5 5.62501C12.5 5.79077 12.4342 5.94974 12.3169 6.06695C12.1997 6.18416 12.0408 6.25001 11.875 6.25001H7.5C7.33424 6.25001 7.17527 6.18416 7.05806 6.06695C6.94085 5.94974 6.875 5.79077 6.875 5.62501C6.875 5.45925 6.94085 5.30027 7.05806 5.18306C7.17527 5.06585 7.33424 5.00001 7.5 5.00001H11.875C12.0408 5.00001 12.1997 5.06585 12.3169 5.18306C12.4342 5.30027 12.5 5.45925 12.5 5.62501Z"
      fill="white"
    />
  </svg>
);

export default FloppyDisk;
