import React from "react";

const ExpandUpdownFill = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M14.1663 7.91667L9.99967 3.75L5.83301 7.91667H14.1663ZM14.1663 12.0833L9.99967 16.25L5.83301 12.0833H14.1663Z"
          fill="#525866"
        />
      </svg>
    </div>
  );
};

export default ExpandUpdownFill;
