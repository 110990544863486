import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ApiCredentials from "./ApiCredentials/ApiCredentials";
import Webhooks from "./Webhooks/Webhooks";
import CheckoutSettings from "./CheckoutSettings/CheckoutSettings";
import GooglePaySettings from "./GooglePaySettings/GooglePaySettings";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import DevelopersIcon from "components/Icons/Developers";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Developers.css";
import SaveIcon from "../Icons/FloppyDisk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Padding } from "@mui/icons-material";

const Developers = () => {
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Developers", component: <DevelopersIcon /> },
  ]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div className="developer-main-container">
      <Box className="developer-header-container">
        <Box className="breadcrumb-section">
          <Typography className="breadcrumb-text">
            <Breadcrumb list={breadcrumbList} />
          </Typography>
        </Box>
        <Box className="developer-row-container">
          <Box>
            <Typography className="developer-text">Developers</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                width: "120px",
              }}
              label="API Keys"
              {...a11yProps(0)}
              className="api-tabs"
            />
            <Tab
              sx={{ textTransform: "capitalize", width: "170px" }}
              label="Webhook Settings"
              {...a11yProps(1)}
              className="webhook-tabs"
            />
            <Tab
              sx={{ textTransform: "capitalize", width: "170px" }}
              label="GooglePay Settings"
              {...a11yProps(2)}
              className="google-tabs"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} sx={{ Padding: "0px" }}>
          <Box className="api-credentials-container">
            <Typography className="credential-text">API Credentials</Typography>
            <Box>
              <ApiCredentials />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="webhooks-main-container">
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className="webhooks-text">Webhooks</Typography>
              <Box>
                <LoadingButton
                  // loading={isLoading}
                  // sx={{ marginTop: "10px" }}
                  variant="contained"
                  // onClick={handleSave}
                  className="save-btn-text"
                  startIcon={<SaveIcon />}
                  //  disabled={saveButtonStatus}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box> */}

            <Box className="webhooks-container">
              <Webhooks />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className="checkout-setting-container">
            {/* <Typography className="checkout-text">
              GooglePay Settings
            </Typography> */}
            <Box>
              <GooglePaySettings />
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      {/* <Box className="api-credentials-container">
        <Typography className="credential-text">API Credentials</Typography>
        <Box>
          <ApiCredentials />
        </Box>
      </Box> */}
      {/* <Box className="webhooks-main-container">
        <Typography className="webhooks-text">Webhooks</Typography>
        <Box className="webhooks-container">
          <Webhooks />
        </Box>
      </Box> */}
      {/* <Box className="checkout-setting-container">
        <Typography className="checkout-text">Checkout Settings</Typography>
        <Box>
          <CheckoutSettings />
        </Box>
      </Box> */}
    </div>
  );
};

export default Developers;
