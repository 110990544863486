import React from "react";

const CaretLeft = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M21.0613 24.9388C21.3431 25.2206 21.5014 25.6027 21.5014 26.0013C21.5014 26.3998 21.3431 26.782 21.0613 27.0638C20.7795 27.3456 20.3973 27.5039 19.9988 27.5039C19.6003 27.5039 19.2181 27.3456 18.9363 27.0638L8.9363 17.0638C8.79646 16.9244 8.6855 16.7588 8.6098 16.5765C8.53409 16.3942 8.49512 16.1987 8.49512 16.0013C8.49512 15.8038 8.53409 15.6084 8.6098 15.426C8.6855 15.2437 8.79646 15.0781 8.9363 14.9388L18.9363 4.93876C19.2181 4.65697 19.6003 4.49866 19.9988 4.49866C20.3973 4.49866 20.7795 4.65697 21.0613 4.93876C21.3431 5.22055 21.5014 5.60274 21.5014 6.00126C21.5014 6.39977 21.3431 6.78197 21.0613 7.06376L12.125 16L21.0613 24.9388Z"
          fill="#343330"
        />
      </svg>
    </>
  );
};

export default CaretLeft;
