import React, { useEffect, useState } from "react";
import "./Settings.css";
// import { fetchData, refreshTokenIfNeeded } from "utils/Utils";
import { httpClient } from "utils/HttpClient";
import { DNA } from "react-loader-spinner";
import {
  URL,
  MERCHANT_CONFIG_ENDPOINT,
  MERCHANT_ID,
  PROCESSOR_ENDPOINT,
  UPDATE_PROCESSOR_ENDPOINT,
} from "constants/Constants";
import ProcessorSettingCard from "components/Elements/ProcessorSettingCard/ProcessorSettingCard";
import NewProcessorSettingCard from "components/Elements/ProcessorSettingCard/NewProcessorSettingCard";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { processors } from "../../utils/processors";
// import useKeyCloak from "../Login/UseKeyCloak";
import ProcessorSettings from "components/Elements/ProcessorSettingCard/ProcessorSettings";

function Settings() {
  // const keycloak = useKeyCloak();
  const [merchantConfig, setMerchantConfig] = useState([]);
  const [processorData, setProcessorData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [addProcessor, setAddProcessor] = useState(null);
  const [screen, setScreen] = useState(0);
  const [settingsData, setSettingsData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  useEffect(() => {
    fetchSettingsData();
  }, [isStatusLoading]);

  const fetchSettingsData = () => {
    console.log("in fetch settings cal");
    // const token = refreshTokenIfNeeded(keycloak);
    // console.log(token);
    httpClient
      .fetchData(
        // token,
        `${URL}${PROCESSOR_ENDPOINT}`,
        "GET"
      )
      .then((data) => {
        // console.log(data);
        setProcessorData(data);
        console.log(processorData);
      });
    httpClient
      .fetchData(
        // token,
        `${URL}${MERCHANT_CONFIG_ENDPOINT}`,
        "GET"
      )
      .then((data) => {
        console.log(data.processorProperties);
        setSettingsData(data);
        setMerchantConfig(data?.processorProperties);
        console.log(merchantConfig);
        setIsLoading(false);
      });
  };
  const onSaveClick = () => {
    setScreen(0);
    setTabValue(1);
    fetchSettingsData();
  };
  const onCancelClick = () => {
    setScreen(0);
    if (!isEdit) {
      setTabValue(0);
    } else {
      setTabValue(1);
      setIsEdit(false);
    }
  };
  const onEditClick = (processor) => {
    setIsEdit(true);
    setAddProcessor(processor);
    setScreen(1);
    setTabValue(3);
  };
  const onStatusClick = (processorId, currentStatus) => {
    setIsStatusLoading((prevValue) => !prevValue);
    const statusData = {
      status: currentStatus === "ACTIVE" ? "DISABLED" : "ACTIVE",
    };
    httpClient
      .fetchData(
        `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processorId}&method=patch`,
        "PUT",
        statusData
      )
      .catch((error) => {
        console.error("Error updating status:", error);
      });
    fetchSettingsData();
  };
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleAddClick = (processor) => {
    console.log(merchantConfig);
    setAddProcessor(processor);
    setScreen(1);
  };

  return !isLoading ? (
    <div className="settings-container">
      {screen === 0 && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="Processor Settings Tabs"
            >
              <Tab label="Processor List" />
              <Tab label="Processor Settings" />
            </Tabs>
          </Box>
          <div className="card-container" hidden={tabValue !== 0}>
            {/* Processor List Tab Content */}
            <Grid container spacing={2}>
              {processors
                .filter((processor) => processor.displayName !== "default")
                .map((processor, index) => (
                  <Grid item key={index} xs={3}>
                    <Card sx={{ minWidth: 275, marginBottom: 4 }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: 48, height: 48 }}
                          src={
                            processor.logo_url ||
                            "https://via.placeholder.com/48"
                          }
                          alt={processor.displayName}
                        />
                        <Typography variant="h5" component="div">
                          {processor.displayName}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          sx={{ marginLeft: "auto" }}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddClick(processor.displayName)}
                          disabled={Object.values(
                            settingsData.processorProperties
                          ).some(
                            (item) =>
                              item.processorCode &&
                              item.processorCode.toLowerCase() ===
                                processor.displayName.toLowerCase()
                          )}
                        >
                          {" "}
                          Add
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}

      {screen === 1 && (
        <div>
          {merchantConfig.some(
            (item) =>
              item.processorCode.toLowerCase() === addProcessor.toLowerCase()
          )
            ? // Render ProcessorSettingCard using item data
              merchantConfig
                .filter(
                  (item) =>
                    item.processorCode.toLowerCase() ===
                    addProcessor.toLowerCase()
                )
                .map((items, key) => (
                  <div key={key}>
                    <ProcessorSettingCard
                      key={key}
                      index={key}
                      data={items}
                      processorData={processorData}
                      onSaveClick={onSaveClick}
                      onCancelClick={onCancelClick}
                      setIsLoading={setIsLoading}
                    />
                  </div>
                ))
            : processorData
                .filter(
                  (item) =>
                    item.code.toLowerCase() === addProcessor.toLowerCase()
                )
                .map((items, key) => (
                  <div key={key}>
                    <NewProcessorSettingCard
                      key={key}
                      index={key}
                      data={items}
                      processorData={processorData}
                      merchantConfig={merchantConfig}
                      onSaveClick={onSaveClick}
                      onCancelClick={onCancelClick}
                      setIsLoading={setIsLoading}
                    />
                  </div>
                ))}
        </div>
      )}

      <div hidden={tabValue !== 1}>
        <ProcessorSettings
          settingsData={settingsData}
          onEditClick={onEditClick}
          onStatusClick={onStatusClick}
        />
      </div>
    </div>
  ) : (
    <DNA
      visible={true}
      height="80"
      width="80"
      ariaLabel="dna-loading"
      wrapperStyle={{}}
      wrapperClass="dna-wrapper"
    />
  );
}

export default Settings;
