import React, { useEffect, useState } from "react";
import "./ConvertDateToMonthDetails.css";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import { useFunctions } from "./FunctionProvider";

const backgroundButtonColor = "button-background-color";
const normalButton = "normal-button";

const monthMap = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const Last30Days = ({
  filter,
  handleFilterChange,
  getDate,
  fetchTransactionDataWithFilter,
  handleClose,
  setDateLabel,
  setTodayDate,
  setYesterdayDate,
}) => {
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const { handleButtonClick, activeButton } = useFunctions();

  useEffect(() => {
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      date.fromDate
    );
    handleFilterChange(filter?.filterOptions?.queryParamInbound, date.toDate);
    getDate(activeButton);
  }, [date]);

  const getLast30DaysRange = () => {
    const today = dayjs();
    const startDate = today.subtract(29, "day").startOf("day");
    const endDate = today.endOf("day");

    return { startDate, endDate };
  };

  const handleLast30DaysClick = () => {
    const { startDate, endDate } = getLast30DaysRange();

    const formattedStartDate = startDate.format("MMM DD YYYY");
    const startDateParts = formattedStartDate.split(" ");
    const startMonthName = startDateParts[0];
    const startDay = startDateParts[1];
    const startYear = startDateParts[2];
    const startMonth = monthMap[startMonthName];

    const startFromDate = `${startYear}-${startMonth}-${startDay}T00:00:00.000+0000`;

    const formattedEndDate = endDate.format("MMM DD YYYY");
    const endDateParts = formattedEndDate.split(" ");
    const endMonthName = endDateParts[0];
    const endDay = endDateParts[1];
    const endYear = endDateParts[2];
    const endMonth = monthMap[endMonthName];

    const endToDate = `${endYear}-${endMonth}-${endDay}T23:59:00.000+0000`;

    setDate({
      fromDate: startFromDate,
      toDate: endToDate,
    });
    fetchData();
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const fetchData = () => {
    const timer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 200);
    setIntervalId(timer);
  };

  useEffect(() => {
    if (time === 1) {
      fetchTransactionDataWithFilter();
      clearInterval(intervalId);
      handleClose();
      setDateLabel(true);
    }
  }, [time]);

  return (
    <div>
      <Button
        className={
          activeButton === "last 30 days" ? backgroundButtonColor : normalButton
        }
        variant="outlined"
        onClick={() => {
          handleLast30DaysClick();
          handleButtonClick("last 30 days");
        }}
      >
        Last 30 Days
      </Button>
    </div>
  );
};

export const Last7Days = ({
  filter,
  handleFilterChange,
  getDate,
  fetchTransactionDataWithFilter,
  handleClose,
  setDateLabel,
  setTodayDate,
  setYesterdayDate,
}) => {
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [currentDate] = useState(new Date());
  const { handleButtonClick, activeButton } = useFunctions();

  useEffect(() => {
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      date.fromDate
    );
    handleFilterChange(filter?.filterOptions?.queryParamInbound, date.toDate);
    getDate(activeButton);
  }, [date]);

  const getLast7DaysRange = () => {
    const today = dayjs();
    const startDate = today.subtract(6, "day").startOf("day");
    const endDate = today.endOf("day");

    return { startDate, endDate };
  };

  const handleLast7DaysClick = () => {
    const { startDate, endDate } = getLast7DaysRange();

    const startFromDate = startDate.format("YYYY-MM-DD");
    const endToDate = endDate.format("YYYY-MM-DD");

    setDate({
      fromDate: `${startFromDate}T00:00:00.000+0000`,
      toDate: `${endToDate}T23:59:00.000+0000`,
    });
    fetchData();
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const fetchData = () => {
    const timer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 200);
    setIntervalId(timer);
  };

  useEffect(() => {
    if (time === 1) {
      fetchTransactionDataWithFilter();
      clearInterval(intervalId);
      handleClose();
      setDateLabel(true);
    }
  }, [time]);

  return (
    <div>
      <Button
        className={
          activeButton === "last 7 days" ? backgroundButtonColor : normalButton
        }
        variant="outlined"
        onClick={() => {
          handleLast7DaysClick();
          handleButtonClick("last 7 days");
        }}
      >
        Last 7 Days
      </Button>
    </div>
  );
};

export const Today = ({
  filter,
  handleFilterChange,
  getDate,
  fetchTransactionDataWithFilter,
  handleClose,
  setDateLabel,
  setTodayDate,
  setYesterdayDate,
}) => {
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const { handleButtonClick, activeButton } = useFunctions();

  useEffect(() => {
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      date.fromDate
    );
    handleFilterChange(filter?.filterOptions?.queryParamInbound, date.toDate);
    getDate(activeButton);
  }, [date]);

  const getTodayDate = () => {
    const today = dayjs();
    const formattedTodayDate = today.format("MMM DD YYYY");
    const dateParts = formattedTodayDate.split(" ");
    const monthName = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];
    const month = monthMap[monthName];

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const fetchData = () => {
    const timer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 200);
    setIntervalId(timer);
  };

  useEffect(() => {
    if (time === 1) {
      fetchTransactionDataWithFilter();
      clearInterval(intervalId);
      handleClose();
      setDateLabel(true);
    }
  }, [time]);

  const handleTodayClick = () => {
    const getFromDate = getTodayDate();

    setDate((prev) => ({
      ...prev,
      fromDate: `${getFromDate}T00:00:00.000+0000`,
      toDate: `${getFromDate}T23:59:00.000+0000`,
    }));
    //fetchTransactionDataWithFilter();

    fetchData();
    setTodayDate(true);
    setYesterdayDate(false);
  };

  return (
    <div>
      <Button
        className={
          activeButton === "today" ? backgroundButtonColor : normalButton
        }
        variant="outlined"
        onClick={() => {
          handleTodayClick();
          handleButtonClick("today");
          // handleTodayDate(true);
        }}
      >
        Today
      </Button>
    </div>
  );
};

export const Yesterday = ({
  filter,
  handleFilterChange,
  getDate,
  fetchTransactionDataWithFilter,
  handleClose,
  setDateLabel,
  setTodayDate,
  setYesterdayDate,
}) => {
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const { handleButtonClick, activeButton } = useFunctions();

  useEffect(() => {
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      date.fromDate
    );
    handleFilterChange(filter?.filterOptions?.queryParamInbound, date.toDate);
    getDate(activeButton);
  }, [date]);

  const getFormattedDate = (date) => {
    const formattedDate = date.format("MMM DD YYYY");
    const dateParts = formattedDate.split(" ");
    const monthName = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];
    const month = monthMap[monthName];
    const fromDate = `${year}-${month}-${day}`;

    return fromDate;
  };

  const handleYesterdayClick = () => {
    const yesterday = dayjs().subtract(1, "day");
    const formattedFromDate = getFormattedDate(yesterday);

    setDate({
      fromDate: `${formattedFromDate}T00:00:00.000+0000`,
      toDate: `${formattedFromDate}T23:59:00.000+0000`,
    });
    fetchData();
    setTodayDate(false);
    setYesterdayDate(true);
  };

  const fetchData = () => {
    const timer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 200);
    setIntervalId(timer);
  };

  useEffect(() => {
    if (time === 1) {
      fetchTransactionDataWithFilter();
      clearInterval(intervalId);
      handleClose();
      setDateLabel(true);
    }
  }, [time]);

  return (
    <div>
      <Button
        variant="outlined"
        className={
          activeButton === "yesterday" ? backgroundButtonColor : normalButton
        }
        onClick={() => {
          handleYesterdayClick();
          handleButtonClick("yesterday");
        }}
      >
        Yesterday
      </Button>
    </div>
  );
};
