import { TableContainer } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div``;

export const Text = styled.h1``;

export const SettingsRowContainer = styled.div``;

export const ContentLabel = styled.div``;

export const Paragraph = styled.p``;

export const Title = styled.h1``;
