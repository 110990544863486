import styled from "styled-components";

export const TableHeaderCell = styled.div``;

export const LabelCont = styled.div``;

export const EmptyTableCell = styled.div`
  display: flex;
  width: 64px;
  height: 36px;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
`;

export const TableRowCell = styled.div``;
