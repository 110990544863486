import React, { useState, useEffect } from "react";
import { httpClient } from "utils/HttpClient";
import { URL, GOOGLEPAY_SETTINGS_ENDPOINT } from "constants/Constants";
import Logout from "Logout";
import { TextField, Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "../../Icons/FloppyDisk";
import "./GooglePaySettings.css";
import { SettingsEthernet } from "@mui/icons-material";

const GooglePaySettings = () => {
  const [googlePaySettings, setGooglePaySettings] = useState({
    merchantName: "",
    merchantId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [merchantNameError, setMerchantNameError] = useState(true);
  const [merchantIdError, setMerchantIdError] = useState(true);

  useEffect(() => {
    fetchGooglePayEventsData();
  }, []);

  const fetchGooglePayEventsData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${GOOGLEPAY_SETTINGS_ENDPOINT}`,
        "GET"
      );
      setGooglePaySettings(data?.googlePaySettings);
      //console.log(data, "GooglePay data");
    } catch (error) {
      console.error("Error fetching webhook data:", error);
    }
  };

  const handleSave = async () => {
    if (googlePaySettings.merchantName === "") {
      setMerchantNameError(false);
    }
    if (googlePaySettings.merchantId === "") {
      setMerchantIdError(false);
    }

    console.log("Save Button");
    if (
      googlePaySettings?.merchantName !== "" &&
      googlePaySettings?.merchantId !== ""
    ) {
      try {
        setIsLoading(true);
        const response = await httpClient.fetchData(
          `${URL}${GOOGLEPAY_SETTINGS_ENDPOINT}`,
          "POST",
          { googlePaySettings }
        );
        if (response.ok) {
          console.log("post created success");
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            console.log("Failed saving data:", response.status);
          }
        }
      } catch (error) {
        console.error("Error saving webhook data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="googlepay-setting-main-container">
      <Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="google-pay-settings-text">
            GooglePay Settings
          </Typography>
          <Grid
          // item
          // xs={12}
          // sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={handleSave}
              className="save-btn-text"
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
        <Grid>
          <Grid className="merchant-name-row-container">
            <Grid className="merchant-name-container">
              <Typography className="merchant-name">
                Google Pay Merchant Name
              </Typography>
            </Grid>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Grid className="merchant-name-input-field-container">
                <TextField
              
                  value={googlePaySettings.merchantName}
                  variant="outlined"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  size="small"
                  className="merchant-name-input-field"
                  onChange={(e) => {
                    setGooglePaySettings((prev) => {
                      return { ...prev, merchantName: e.target.value };
                    });
                  }}
                  placeholder="Add Merchant Name"
                  
                />
              </Grid>
              <p
                className={
                  merchantNameError
                    ? "hide-text"
                    : googlePaySettings.merchantName.length > 0
                    ? "hide-text"
                    : "error-message"
                }
              >
                merchant name is required
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid>
          <Grid className="merchant-name-row-container">
            <Grid className="merchant-name-container">
              <Typography className="merchant-name">
                Google Pay Merchant Id
              </Typography>
            </Grid>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Grid className="merchant-name-input-field-container">
                <TextField
                  value={googlePaySettings.merchantId}
                  fullWidth
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  size="small"
                  placeholder="Add Merchant Id"
                  className="merchant-name-input-field"
                  onChange={(e) => {
                    setGooglePaySettings((prev) => {
                      return { ...prev, merchantId: e.target.value };
                    });
                  }}
                />
              </Grid>
              <p
                className={
                  merchantIdError
                    ? "hide-text"
                    : googlePaySettings.merchantId.length > 0
                    ? "hide-text"
                    : "error-message"
                }
              >
                merchant Id is required
              </p>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            // loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
            className="save-btn-text"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default GooglePaySettings;
