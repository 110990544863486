import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./CheckoutSettings.css";
import { httpClient } from "utils/HttpClient";
import {
  URL,
  CHECKOUT_SETTINGS_ENDPOINT,
  MERCHANT_ID,
} from "constants/Constants";
import Logout from "Logout";
import SaveIcon from "../../Icons/FloppyDisk";
function CheckoutSettings() {
  const [logoUrl, setLogoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchCheckoutSettingsData();
  }, []);

  const fetchCheckoutSettingsData = async () => {
    try {
      const fetchedData = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}`,
        "GET"
      );

      setLogoUrl(fetchedData.logoUrl || "");
      setTitle(fetchedData.title || "");
    } catch (error) {
      console.error("Error fetching checkout settings:", error);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}`,
        "POST",
        {
          logoUrl,
          title,
        }
      );

      if (response.ok) {
        return response.json();
      } else {
        // Check if the status code is 401
        if (response.status === 401) {
          Logout();
        } else {
          console.log("Failed saving data:", response.status);
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="checkout-setting-main-container">
      <Grid>
        <Grid>
          <Grid className="logo-url-add-url-row-container">
            <Grid className="logo-url-container">
              <Typography className="logo-url">Logo URL</Typography>
            </Grid>

            <Grid className="add-logo-label-container">
              <TextField
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                className="logo-url-search"
                value={logoUrl}
                onChange={(e) => setLogoUrl(e.target.value)}
                placeholder="Add URL"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Grid className="logo-url-add-url-row-container">
            <Grid className="logo-url-container">
              <Typography className="logo-url">Title</Typography>
            </Grid>
            <Grid className="add-logo-label-container">
              <TextField
                fullWidth
                value={title}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                size="small"
                placeholder="Add title"
                className="logo-url-search"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
            className="save-btn-text"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default CheckoutSettings;
