import React, { createContext, useContext } from "react";
import { jwtDecode } from "jwt-decode";

export const ServiceContext = createContext();

export const ServiceProvider = ({ value, children }) => {
  const getId = jwtDecode(value?.token);
  const merchantId = getId?.merchantId;
  console.log(merchantId, "id");
  return (
    <ServiceContext.Provider value={{ ...value }}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useServiceContext = () => useContext(ServiceContext);
